
import { defineComponent, ref, onMounted, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiBase, ApiAccount, ApiChanel } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { commonChangeDefaultDate } from "@/core/directive/function/common";

export default defineComponent({
  name: "add-merchant-modal",
  props: {
    mode: String, //accounts | leads
  },
  components: {},
  emits: ["update-list"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const addAccountModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const industryOptions = ref([]);
    const sourceOptions = ref([]);
    const channelOptions = ref([]);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      status: 10,
      crm_account_id: "",
      salutation: "",
      first_name: "",
      last_name: "",
      source: "",
      channel: "",
      industry: "",
      mb_segmentation: [],
      email: "",
      phone: "",
      birthday: "",
      stage: 0,
    });

    const mobileRegexp = /^1[23456789]\d{9}$/;
    const rules = ref({
      salutation: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      first_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      last_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      source: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      channel: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      industry: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      phone: [
        {
          required: false,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (!mobileRegexp.test(value) && value != "") {
                reject(t("influencerAccount.enterMobile"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    });

    watch(
      () => props.mode,
      (newVal) => {
        if (newVal == "accounts") {
          formData.value.stage = 0;
        } else if (newVal == "leads") {
          formData.value.stage = 1;
        }
      }
    );

    const initStatus = () => {
      if (props.mode == "accounts") {
        formData.value.stage = 0;
      } else if (props.mode == "leads") {
        formData.value.stage = 1;
      }
    };

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["distribution_industry", "global_account_source"],
      });
      if (data.code == 0) {
        industryOptions.value = data.data.distribution_industry.items;
        sourceOptions.value = data.data.global_account_source.items;
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiAccount.addAccount(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addAccountModalRef.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const getChannelOptions = () => {
      ApiChanel.getChanelList({})
        .then(({ data }) => {
          if (data.code == 0) {
            channelOptions.value = data.data.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleDiscard = () => {
      hideModal(addAccountModalRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onMounted(() => {
      initStatus();
      getDropdownOptions();
      getChannelOptions();
    });

    return {
      t,
      commonChangeDefaultDate,
      loading,
      formData,
      rules,
      formRef,
      addAccountModalRef,
      submitButton,
      industryOptions,
      sourceOptions,
      channelOptions,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
