
import { defineComponent, ref, onMounted } from "vue";
import { ApiBase, ApiChanel } from "@/core/api";
import { useI18n } from "vue-i18n";
import { string } from "yup";

interface Filter {
  system_id: string;
  crm_account_id: string;
  first_name: string;
  last_name: string;
  source: string;
  channel: string;
  industry: string;
  mobile: number | string;
  assigned_group: string;
}

export default defineComponent({
  name: "filter-account-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref<Filter>({
      system_id: "",
      crm_account_id: "",
      first_name: "",
      last_name: "",
      source: "",
      channel: "",
      industry: "",
      mobile: "",
      assigned_group: ""
    });

    const industryOptions = ref([]);
    const channelOptions = ref([]);
    const sourceOptions = ref([]);
    const groupOptions = ref([]);

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["distribution_industry", "global_account_source"],
      });
      if (data.code == 0) {
        industryOptions.value = data.data.distribution_industry.items;
        sourceOptions.value = data.data.global_account_source.items;
      }
    };

    const getChannelOptions = () => {
      ApiChanel.getDistributionDataSource({ parent_id: 33 })
        .then(({ data }) => {
          if (data.code == 0) {
            channelOptions.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getGroupOptions = async () => {
      const { data } = await ApiBase.getInfluencerGroupSourceData({
        max_item: "all",
      });
      if (data.code == 0) {
        groupOptions.value = data.data;
      }
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      getDropdownOptions();
      getChannelOptions();
      getGroupOptions();
    });

    return {
      t,
      formData,
      formRef,
      sourceOptions,
      channelOptions,
      industryOptions,
      groupOptions,
      submit,
      handleReset,
    };
  },
});
